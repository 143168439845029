import React, { useState, useEffect } from "react";
import Notify from "../../../common/Notify";
import "./style.css";
import {
  addMcqTypeApiCall,
  getAllMcqTypeListApiCall,
  updateMcqTypeApiCall,
} from "../../services/ApiCall";
import { backendImagePath, imageNotfound } from "../../../common/ImporentDate";

const McqTypeList = () => {
  const [addNew, setAddNew] = useState(false);
  const [message, setMessage] = useState([]);
  const [editMcqType, setEditMcqType] = useState({
    name: "",
    slug: "",
    image: null,
    id: "",
    active: 0,
    public: 0,
    username: localStorage.getItem("username"),
    token: localStorage.getItem("token"),
  });
  const [mcqTypeList, setMcqTypeList] = useState([]);
  const [status, setStatus] = useState(false);
  const [formError, setFormError] = useState({});
  const [Input, setInput] = useState({
    name: "",
    slug: "",
    image: null,
    active: 0,
    username: localStorage.getItem("username"),
    token: localStorage.getItem("token"),
  });

  useEffect(() => {
    getAllMcqType();
  }, []);

  const inputChange = (e) => {
    setInput({
      ...Input,
      [e.target.name]: e.target.value,
    });
  };

  const editInputChange = (e) => {
    setEditMcqType({
      ...editMcqType,
      [e.target.name]: e.target.value,
    });
  };

  const reSetForm = () => {
    setInput({
      ...Input,
      name: "",
      slug: "",
      active: 0,
      image: null,
    });
    setAddNew(false);
  };

  const saveSocialLink = () => {
    addMcqTypeApiCall(Input)
      .then((res) => {
        setFormError(res?.error ? res?.error : {});
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          reSetForm();
          getAllMcqType();
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllMcqType = () => {
    getAllMcqTypeListApiCall()
      .then((res) => {
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          setMcqTypeList(res?.mcq_type);
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateMcqType = () => {
    updateMcqTypeApiCall(editMcqType)
      .then((res) => {
        setFormError(res?.error ? res?.error : {});
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          setEditMcqType({
            name: "",
            slug: "",
            id: "",
            active: 0,
            public: 0,
            image: null,
            username: localStorage.getItem("username"),
            token: localStorage.getItem("token"),
          });
          getAllMcqType();
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {message?.map((res) => (
        <Notify
          msg={res}
          status={status}
          setMessage={() => {
            setMessage([]);
          }}
        />
      ))}
      <div className="row">
        <div className="col-md-6 page_heading">
          <h3>McqTypeList</h3>
        </div>
        <div className="col-md-6 create_new_with_heading">
          <button
            onClick={() => {
              setAddNew(true);
            }}
          >
            Add New
          </button>
        </div>
        {addNew && (
          <div className="col-md-12 row">
            <div className="col-md-3">
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={Input.name}
                onChange={inputChange}
              />
              <p className="input_error_message">{formError.name}</p>
            </div>
            <div className="col-md-3">
              <input
                type="text"
                name="slug"
                value={Input.slug}
                placeholder="Slug"
                onChange={inputChange}
              />
              <p className="input_error_message">{formError.slug}</p>
            </div>
            <div className="col-md-2">
              <select name="active" onChange={inputChange} value={Input.active}>
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </select>
            </div>
            <div className="col-md-2">
              {Input?.imageShow && (
                <img
                  style={{ width: "50%" }}
                  src={Input?.imageShow}
                  alt={Input?.name}
                />
              )}
              <input
                type="file"
                onChange={(e) => {
                  if (e.target.files.length != 0) {
                    setInput({
                      ...Input,
                      image: e.target.files[0],
                      imageShow: URL.createObjectURL(e.target.files[0]),
                    });
                  }
                }}
              />
            </div>
            <div className="col-md-2">
              <button
                onClick={() => {
                  saveSocialLink();
                }}
              >
                Save
              </button>
            </div>
          </div>
        )}
        <div className="col-md-12">
          <table class="table">
            <thead>
              <tr>
                <th>S.No.</th>
                <th scope="col">Image</th>
                <th scope="col">Name</th>
                <th scope="col">Slug</th>
                <th scope="col">Active</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody>
              {mcqTypeList?.map((res, index) => (
                <tr key={res.id}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    {editMcqType?.id == res.id ? (
                      <>
                        <input
                          type="file"
                          onChange={(e) => {
                            if (e.target.files.length != 0) {
                              setEditMcqType({
                                ...editMcqType,
                                image: e.target.files[0],
                                imageShow: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                              });
                            }
                          }}
                        />
                        <img
                          src={
                            editMcqType?.imageShow
                              ? editMcqType?.imageShow
                              : res?.image
                              ? backendImagePath + res?.image
                              : backendImagePath+imageNotfound
                          }
                          alt={res?.name}
                        />
                      </>
                    ) : (
                      <img
                        src={
                          res?.image
                            ? backendImagePath + res?.image
                            : backendImagePath+imageNotfound
                        }
                        alt={res?.name}
                      />
                    )}
                  </td>
                  <th scope="row">
                    {editMcqType?.id == res.id ? (
                      <>
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          value={editMcqType.name}
                          onChange={editInputChange}
                        />
                        <p className="input_error_message">{formError.name}</p>
                      </>
                    ) : (
                      res?.name
                    )}
                  </th>
                  <td>
                    {" "}
                    {editMcqType?.id == res.id ? (
                      <>
                        <input
                          type="text"
                          name="slug"
                          placeholder="slug"
                          value={editMcqType.slug}
                          onChange={editInputChange}
                        />
                        <p className="input_error_message">{formError.slug}</p>
                      </>
                    ) : (
                      res?.slug
                    )}
                  </td>
                  <td>
                    {editMcqType?.id == res.id ? (
                      <>
                        <select
                          name="active"
                          onChange={editInputChange}
                          value={editMcqType.active}
                        >
                          <option value={1}>Active</option>
                          <option value={0}>Inactive</option>
                        </select>
                      </>
                    ) : res?.active == "1" ? (
                      <span className="active_social_link">Active</span>
                    ) : (
                      <span className="in_active_social_link">Inactive</span>
                    )}
                    {}
                  </td>
                  <td>
                    {editMcqType?.id == res.id ? (
                      <>
                        <select
                          name="public"
                          onChange={editInputChange}
                          value={editMcqType.public}
                        >
                          <option value={1}>public</option>
                          <option value={0}>Inpublic</option>
                        </select>
                      </>
                    ) : res?.public == "1" ? (
                      <span className="public_social_link">public</span>
                    ) : (
                      <span className="in_public_social_link">Inpublic</span>
                    )}
                    {}
                  </td>
                  <td>
                    {editMcqType?.id == res.id ? (
                      <button
                        onClick={() => {
                          updateMcqType();
                        }}
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setEditMcqType({
                            ...editMcqType,
                            ...res,
                          });
                        }}
                      >
                        Edit
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default McqTypeList;
