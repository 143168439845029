import React from "react";
import { useState } from "react";
import { backendImagePath } from "../common/ImporentDate";
import { addImageApiCall, getImageListApiCall ,deleteImageApiCall} from "./services/ApiCall";
import Notify from "../common/Notify";
import { useEffect } from "react";

const ImageList = () => {
  const [List, setList] = useState([]);
  const [Input, setInput] = useState({});
  const [message, setMessage] = useState([]);
  const [status, setStatus] = useState(false);

  useEffect(()=>{
    getImage();
  },[])

  const uploadImage = () => {
    addImageApiCall(Input)
      .then((res) => {
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          getImage();
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteImage = (id) => {
    deleteImageApiCall(id)
      .then((res) => {
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          getImage();
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getImage = () => {
    getImageListApiCall()
      .then((res) => {
        if (res?.status == true) {
          setList(res?.data);
        }else{
          setList([]);
          setStatus(res?.status ? res?.status : false);
          setMessage(res?.api_message ? res?.api_message : []);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const copyImageUrl = async (text)=>{
    try {
      await navigator.clipboard.writeText(text);
      setStatus(true);
      setMessage(['Text Copy'])
    } catch (error) {
      setStatus(false);
      setMessage(['Text Not Copy'])
    }
  }
  return (
    <div className="row">
      {message?.map((res) => (
        <Notify
          msg={res}
          status={status}
          setMessage={() => {
            setMessage([]);
          }}
        />
      ))}
      <div className="col-md-6">
        <input
          type="file"
          onChange={(e) => {
            if (e.target.files.length != 0) {
              setInput({
                image: e.target.files[0],
                imageShow: URL.createObjectURL(e.target.files[0]),
              });
            }
          }}
        />
      </div>
      <div className="col-md-3">
        {Input?.imageShow && (
          <img style={{ width: "50%" }} src={Input?.imageShow} />
        )}
      </div>
      <div className="col-md-3">
        <button
          className="btn btn-primary"
          onClick={() => {
            Input?.image && uploadImage();
          }}
        >
          Upload Image
        </button>
      </div>
      <div className="col-md-12">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">S.No.</th>
              <th scope="col">Image</th>
              <th scope="col">Image name</th>
              <th>Copy Url</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {List.map((res, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>
                  <img src={backendImagePath + res?.image} />
                </td>
                <td><textarea>{backendImagePath + res?.image}</textarea> </td>
                <td>
                  <button onClick={()=>{
                    copyImageUrl(backendImagePath + res?.image)
                  }}>Copy Url</button>
                </td><td>
                  <button onClick={()=>{
                    deleteImage(res?.id)
                  }}>delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ImageList;
