import React, { useState, useEffect } from "react";
import "./style.css";
import {
  getPageContentDetailsApiCall,
  updatePageContentApiCall,
} from "../../services/ApiCall";
import { useNavigate, useParams } from "react-router-dom";
import MyRouts from "../../MyRouts";
import Notify from "../../../common/Notify";
import JoditEditor from "jodit-react";

const PageContentEdit = () => {
  const config = {
    readonly: false,
    height: 500,
  };
  const [Input, setInput] = useState({});

  const [message, setMessage] = useState([]);

  const [status, setStatus] = useState(false);
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  const routeChange = (page) => {
    navigate(page);
  };

  useEffect(() => {
    id && getDetails();
  }, [id]);

  const getDetails = () => {
    getPageContentDetailsApiCall(id)
      .then((res) => {
        setFormError(res?.error);
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          setInput({ ...res?.data[0] });
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateJob = () => {
    updatePageContentApiCall(Input)
      .then((res) => {
        setFormError(res?.error ? res?.error : {});
        setStatus(res?.status ? res?.status : false);
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const inputChange = (e) => {
    setInput({
      ...Input,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <div className="row">
        {message?.map((res) => (
          <Notify
            msg={res}
            status={status}
            setMessage={() => {
              setMessage([]);
            }}
          />
        ))}

        <div className="col-md-6 page_heading">
          <h3>Page Content Edit</h3>
        </div>
        <div className="col-md-6 create_new_with_heading">
          <button
            onClick={() => {
              routeChange(MyRouts.contentList);
            }}
          >
            Back
          </button>
        </div>
        <hr />

       
        <div className="col-md-6 mtop_20">
          <label>type</label>
          <input
            type="text"
            className="form-control "
            name="type"
            value={Input?.type}
            placeholder="type"
            onChange={inputChange}
          />
          <p className="input_error_message">{formError?.type}</p>
        </div>
        
        <div className="col-md-6 mtop_20">
          <label>Status select</label>
          <select
            name="active"
            className="form-control "
            onChange={inputChange}
            value={Input?.active}
          >
            <option value={1}>Active</option>
            <option value={0}>Inactive</option>
          </select>
        </div>
        <div className="col-md-12 mtop_20">
          <label>title</label>
          <input
            type="text"
            name="title"
            className="form-control "
            placeholder="title"
            value={Input?.title}
            onChange={inputChange}
          />
          <p className="input_error_message">{formError?.title}</p>
        </div>
        <div className="col-md-12 mtop_20">
          <JoditEditor
            config={config}
            value={Input.des}
            onBlur={(value) => {
              setInput({
                ...Input,
                des: value,
              });
            }}
          />
        </div>
        <div className="col-md-6 mtop_20">
          <button
            className="btn btn-primary"
            onClick={() => {
              updateJob();
            }}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};
export default PageContentEdit;
