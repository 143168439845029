import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Notify from "../../common/Notify";
import MyRouts from "../MyRouts";
import { LogOutApiCall, userValidApiCall } from "../services/ApiCall";
import "./style.css";

const Sidebar_header = () => {
  const navigate = useNavigate();

  const [message, setMessage] = useState([]);
  const [userDetails, setUserDetails] = useState({
    username: localStorage.getItem("username"),
    full_name: localStorage.getItem("full_name"),
    token: localStorage.getItem("token"),
  });
  const [status, setStatus] = useState(false);
  const [isUserValid, setisUserValid] = useState(false);

  const routeChange = (page) => {
    navigate(page);
  };

  useEffect(() => {
    userValidCheck();
  }, []);

  const logoutUser = () => {
    LogOutApiCall()
      .then((res) => {
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          localStorage.removeItem("username");
          localStorage.removeItem("full_name");
          localStorage.removeItem("token");
          routeChange(MyRouts.Login);
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const userValidCheck = () => {
    userValidApiCall()
      .then((res) => {
        setStatus(res?.status ? res?.status : false);
        if (res?.status == false) {
          localStorage.removeItem("username");
          localStorage.removeItem("full_name");
          localStorage.removeItem("token");
          routeChange(MyRouts.Login);
        }
        if (res?.status == true) {
          setisUserValid(true);
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {isUserValid ? (
        <div className="row">
          {message?.map((res) => (
            <Notify 
              msg={res}
              status={status}
              setMessage={() => {
                setMessage([]);
              }}
            />
          ))}
          <div className="col-md-3 sidebar_menu">
            <h2>{userDetails.full_name}</h2>
            <h4>{userDetails.username}</h4>
            <ul>
              <li
                onClick={() => {
                  routeChange(MyRouts.jobList);
                }}
              >
                 Posts
              </li>
              <li
                onClick={() => {
                  routeChange(MyRouts.mcqList);
                }}
              >
                Mcq
              </li>
              <li
                onClick={() => {
                  routeChange(MyRouts.mcqTypeUrl);
                }}
              >
                 Mcq Type
              </li>
              <li
                onClick={() => {
                  routeChange(MyRouts.SocialUrl);
                }}
              >
                Social
              </li>
              <li
                onClick={() => {
                  routeChange(MyRouts.contentList);
                }}
              >
                Page Content
              </li>
              <li
                onClick={() => {
                  routeChange(MyRouts.teamList);
                }}
              >
                Our team
              </li>
              <li
                onClick={() => {
                  routeChange(MyRouts.imageCreate);
                }}
              >
                Image Create
              </li>
              <li
                onClick={() => {
                  routeChange(MyRouts.siteQuery);
                }}
              >
                Site Query
              </li>
              <li
                onClick={() => {
                  logoutUser();
                }}
              >
                Logout
              </li>
            </ul>
          </div>
          <div className="col-md-9 sidebar_menu_main_content">
            <Outlet />
          </div>
        </div>
      ) : (
        <div>loading</div>
      )}
    </>
  );
};

export default Sidebar_header;
