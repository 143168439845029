import React, { useState, useEffect } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import MyRouts from "../../MyRouts";

import { getAllJobListApiCall } from "../../services/ApiCall";
import Notify from "../../../common/Notify";
import { backendImagePath, imageNotfound, typeList } from "../../../common/ImporentDate";

const pageLengthArray = [10, 20, 30, 50, 70, 100, 500];

const JobList = () => {
  const [message, setMessage] = useState([]);
  const [JobList, setJobList] = useState([]);
  const [status, setStatus] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLength, setPageLength] = useState(10);
  const [PostType, setPostType] = useState("");
  const [pageArray, setPageArray] = useState([]);

  useEffect(() => {
    PostType &&  getAllJobs();
  }, [page, pageLength,PostType]);

  const getAllJobs = () => {
    getAllJobListApiCall({ page, pageLength ,PostType})
      .then((res) => {
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          setJobList(res?.data);
          setPageArray(
            [...new Array(Math.ceil(+res?.page / pageLength))].map(() => 0)
          );
        } else {
          setJobList([]);
          setPageArray([])
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const navigate = useNavigate();
  const routeChange = (page) => {
    navigate(page);
  };

  return (
    <div>
      {message?.map((res) => (
        <Notify
          msg={res}
          status={status}
          setMessage={() => {
            setMessage([]);
          }}
        />
      ))}
      <div className="row">
        <div className="col-md-3 page_heading">
          <h3>Posts List</h3>
        </div>

        <div className="col-md-3 create_new_with_heading">
          <select
            className="form-control "
            name="type"
            value={PostType}
            onChange={(e)=>{
              setPostType(e.target.value)
              setPage(1);
            }}
          >
            {typeList.map((res) => (
              <option value={res.value}>{res.name}</option>
            ))}
          </select>
        </div>
        <div className="col-md-3 create_new_with_heading">
          <select
            className="form-control"
            onChange={(e) => {
              setPage(1);
              setPageLength(e.target.value);
            }}
            value={pageLength}
          >
            <option value="">Select Page Length</option>
            {pageLengthArray.map((res) => (
              <option value={res} key={res}>
                {res}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-3 create_new_with_heading">
          <button
            onClick={() => {
              routeChange(MyRouts.jobAdd);
            }}
          >
            Add New Post
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">S.No.</th>
                <th scope="col">Title</th>
                <th scope="col">Image</th>
                <th scope="col">Active</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody>
              {JobList?.map((res, index) => (
                <tr>
                  <td scope="col">{index + 1}</td>
                  <td scope="col">{res?.title}</td>
                  <td scope="col"><img
                        src={
                          res?.image
                            ? backendImagePath + res?.image
                            : backendImagePath+imageNotfound
                        }
                        alt={res?.name}
                      /></td>
                  <td scope="col">
                    {res?.active == "1" ? (
                      <span className="active">Active</span>
                    ) : (
                      <span className="in_active">Inactive</span>
                    )}
                  </td>
                  <td scope="col">
                    <button
                      onClick={() => routeChange(MyRouts.jobEdit + res?.id)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-md-12">
          {pageArray.map((res, index) => (
            <button
              onClick={() => {
                setPage(index + 1);
              }}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
export default JobList;
