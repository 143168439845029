import { apiBaseUrl } from "../../common/ImporentDate";

const loginApiUrl = apiBaseUrl + "users/login.php";
const logOutApiUrl = apiBaseUrl + "users/logout.php";
const userValidApiUrl = apiBaseUrl + "users/uservalid.php";
const signupApiUrl = apiBaseUrl + "users/create.php";

const updateSocialLinkApiUrl = apiBaseUrl + "social_link/update.php";
const getSoicalLinkApiUrl = apiBaseUrl + "social_link/get_link.php";

const getAllMcqTypeApiUrl = apiBaseUrl + "mcq_type/get_all_mcq_type.php";
const getMcqTypeApiUrl = apiBaseUrl + "mcq_type/type_list.php";
const addMcqTypeApiUrl = apiBaseUrl + "mcq_type/mcq_type_add.php";
const updateMcqTypeApiUrl = apiBaseUrl + "mcq_type/mcq_type_update.php";

const addMcqApiUrl = apiBaseUrl + "mcqs/add.php";
const updateMcqApiUrl = apiBaseUrl + "mcqs/mcq_update.php";
const getAllMcqApiUrl = apiBaseUrl + "mcqs/get_all_mcq.php";
const getMcqDetailsApiUrl = apiBaseUrl + "mcqs/get_mcq_detail.php";

const getAlljobApiUrl = apiBaseUrl + "job/get_all_job.php";
const addJobApiUrl = apiBaseUrl + "job/add_job.php";
const updateJobApiUrl = apiBaseUrl + "job/update_job.php";
const getJobDetailsApiUrl = apiBaseUrl + "job/get_all_job_by_id.php";

const addImageApiUrl = apiBaseUrl+"my_image/add.php";
const getImageListApiUrl = apiBaseUrl+"my_image/get_image.php";
const deleteImageApiUrl = apiBaseUrl+"my_image/delete.php";

const addPageContentApiUrl = apiBaseUrl+"content/add-content.php";
const updatePageContentApiUrl = apiBaseUrl+"content/update-content.php";
const getAllPageContentApiUrl = apiBaseUrl+"content/get-all-content.php";
const getPageContentDetailsApiUrl = apiBaseUrl+"content/get-details-content.php";

const addTeamApiUrl = apiBaseUrl+"team/add-team.php";
const updateTeamApiUrl = apiBaseUrl+"team/update-team.php";
const getAllTeamApiUrl = apiBaseUrl+"team/get-all-team.php";
const getTeamDetailsApiUrl = apiBaseUrl+"team/get-details-team.php";



const getAllSiteQueryApiUrl = apiBaseUrl+"site/get-all-query.php";



export {
  getAllSiteQueryApiUrl,
  updateTeamApiUrl,
  getTeamDetailsApiUrl,
  addTeamApiUrl,
  getAllTeamApiUrl,
  updatePageContentApiUrl,
  getPageContentDetailsApiUrl,
  addPageContentApiUrl,
  getAllPageContentApiUrl,
  deleteImageApiUrl,
  getImageListApiUrl,
  addImageApiUrl,
  getJobDetailsApiUrl,
  updateJobApiUrl,
  addJobApiUrl,
  updateMcqApiUrl,
  getMcqDetailsApiUrl,
  getAllMcqTypeApiUrl,
  getAllMcqApiUrl,
  updateMcqTypeApiUrl,
  getMcqTypeApiUrl,
  addMcqTypeApiUrl,
  loginApiUrl,
  signupApiUrl,
  updateSocialLinkApiUrl,
  getSoicalLinkApiUrl,
  logOutApiUrl,
  userValidApiUrl,
  addMcqApiUrl,
  getAlljobApiUrl,
};
