import React, { useState, useEffect } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import MyRouts from "../../MyRouts";

import { getAllPageContentApiCall } from "../../services/ApiCall";
import Notify from "../../../common/Notify";

const PageContentList = () => {
  const [message, setMessage] = useState([]);
  const [PageContent, setPageContent] = useState([]);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    getPageContentList();
  }, []);

  const getPageContentList = () => {
    getAllPageContentApiCall()
      .then((res) => {
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          setPageContent(res?.data);
        } else {
          setPageContent([]);
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const navigate = useNavigate();
  const routeChange = (page) => {
    navigate(page);
  };

  return (
    <div>
      {message?.map((res) => (
        <Notify
          msg={res}
          status={status}
          setMessage={() => {
            setMessage([]);
          }}
        />
      ))}
      <div className="row">
        <div className="col-md-6 page_heading">
          <h3>Page Content List</h3>
        </div>
        <div className="col-md-6 create_new_with_heading">
          <button
            onClick={() => {
              routeChange(MyRouts.contentAdd);
            }}
          >
            Add New Page Content
          </button>
        </div>
      </div>

        <div className="row">
          <div className="col-md-12">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Title</th>
                  <th scope="col">Active</th>
                  <th scope="col">Type</th>
                  <th scope="col">Edit</th>
                </tr>
              </thead>
              <tbody>
                {PageContent?.map((res, index) => (
                  <tr>
                    <td scope="col">{index + 1}</td>
                    <td scope="col">{res?.title}</td>
                    <td scope="col">{res?.type}</td>
                    <td scope="col">
                      {res?.active == "1" ? (
                        <span className="active">Active</span>
                      ) : (
                        <span className="in_active">Inactive</span>
                      )}
                    </td>
                    <td scope="col">
                      <button onClick={() => routeChange(MyRouts.contentEdit+res?.id)}>Edit</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
    </div>
  );
};
export default PageContentList;
