// const backendImagePath = "http://localhost/php-api/images/";
// const apiBaseUrl = "http://localhost/php-api/";

// const apiBaseUrl = "https://myapi.omitechguru.com/";
// const backendImagePath = "https://myapi.omitechguru.com/images/";

const apiBaseUrl = "https://myapi.newvacancy.org/";
const backendImagePath = "https://myapi.newvacancy.org/images/";

const imageNotfound = "image_not_found.png";

const typeList = [
  {
    name: "Select Post Type",
    value: "",
  },
  {
    name: "Job",
    value: "job",
  },
  {
    name: "Result",
    value: "result",
  },
  {
    name: "Job News",
    value: "jobnews",
  },
  {
    name: "Admit Card",
    value: "admitcard",
  },
  {
    name: "Answar Key",
    value: "answarkey",
  },
  {
    name: "Exam Date",
    value: "examdate",
  },
  {
    name: "Syllabus",
    value: "syllabus",
  },
  {
    name: "Blog",
    value: "blog",
  }, 
  {
    name: "Technology",
    value: "technology",
  }, 
  {
    name: "Other",
    value: "other",
  },
];

export { apiBaseUrl, backendImagePath, imageNotfound,typeList };
