import React from "react";

const Notify = ({ status, msg ,setMessage}) => {
  setTimeout(() => {
    setMessage();
  }, 3000);
  
  return (
    <>
      {msg && (
        <div
          className={
            status == true
              ? "notify_div_success notify_div"
              : "notify_div_error notify_div"
          }
        >
          <p>{msg}</p>
        </div>
      )}
    </>
  );
};
export default Notify;
