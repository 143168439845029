import React, { useState } from "react";
import "./style.css";
import { addTeamApiCall} from "../../services/ApiCall";
import { useNavigate } from "react-router-dom";
import MyRouts from "../../MyRouts";
import Notify from "../../../common/Notify";

const TeamAdd = () => {
  
  const [Input, setInput] = useState({
    name: "",
    image: null,
    des: "",
    active: 0,
  });

  const [message, setMessage] = useState([]);

  const [status, setStatus] = useState(false);
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();

  const routeChange = (page) => {
    navigate(page);
  };

  const inputChange = (e) => {
    setInput({
      ...Input,
      [e.target.name]: e.target.value,
    });
  };

  const reSetForm = () => {
    setInput({
      ...Input,
      name: "",
      active: 0,
      image: null,
      des: "",
    });
  };

  const saveNewTeam = () => {
    addTeamApiCall(Input)
      .then((res) => {
        setFormError(res?.error ? res?.error : {});
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          reSetForm();
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="row">
        {message?.map((res) => (
          <Notify
            msg={res}
            status={status}
            setMessage={() => {
              setMessage([]);
            }}
          />
        ))}
        <div className="col-md-6 page_heading">
          <h3>Team Add</h3>
        </div>
        <div className="col-md-6 create_new_with_heading">
          <button
            onClick={() => {
              routeChange(MyRouts.teamList);
            }}
          >
            Back
          </button>
        </div>
        <hr />

        <div className="col-md-6 mtop_20">
          <label>name</label>
          <input
            type="text"
            name="name"
            className="form-control "
            placeholder="name"
            value={Input.name}
            onChange={inputChange}
          />
          <p className="input_error_message">{formError.name}</p>
        </div>
        <div className="col-md-6 mtop_20">
        <label>des</label>
          <textarea
            type="text"
            className="form-control "
            name="des"
            value={Input?.des}
            placeholder="des"
            onChange={inputChange}
          />
          <p className="input_error_message">{formError?.des}</p>
        </div>
        <div className="col-md-6 mtop_20">
          <label>Status select</label>
          <select
            name="active"
            className="form-control "
            onChange={inputChange}
            value={Input.active}
          >
            <option value={1}>Active</option>
            <option value={0}>Inactive</option>
          </select>
        </div>
        <div className="col-md-6 mtop_20">
          {Input?.imageShow && (
            <img
              style={{ width: "50%" }}
              src={Input?.imageShow}
              alt={Input?.title}
            />
          )}
          <label>Image</label>
          <input
            className="form-control "
            type="file"
            onChange={(e) => {
              if (e.target.files.length != 0) {
                setInput({
                  ...Input,
                  image: e.target.files[0],
                  imageShow: URL.createObjectURL(e.target.files[0]),
                });
              }
            }}
          />
        </div>
        <div className="col-md-6 mtop_20">
          <button
            className="btn btn-primary"
            onClick={() => {
              saveNewTeam();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
export default TeamAdd;