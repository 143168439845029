import React, { useState, useEffect } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import MyRouts from "../../MyRouts";

import { getAllTeamApiCall } from "../../services/ApiCall";
import Notify from "../../../common/Notify";
import { backendImagePath, imageNotfound } from "../../../common/ImporentDate";

const TeamList = () => {
  const [message, setMessage] = useState([]);
  const [DataList, setDataList] = useState([]);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    getAllJobs();
  }, []);

  const getAllJobs = () => {
    getAllTeamApiCall()
      .then((res) => {
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          setDataList(res?.data);
        } else {
          setDataList([]);
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const navigate = useNavigate();
  const routeChange = (page) => {
    navigate(page);
  };

  return (
    <div>
      {message?.map((res) => (
        <Notify
          msg={res}
          status={status}
          setMessage={() => {
            setMessage([]);
          }}
        />
      ))}
      <div className="row">
        <div className="col-md-4 page_heading">
          <h3>Team List</h3>
        </div>

        <div className="col-md-4 create_new_with_heading">
          <button
            onClick={() => {
              routeChange(MyRouts.teamAdd);
            }}
          >
            Add New Team
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">S.No.</th>
                <th scope="col">Name</th>
                <th scope="col">Image</th>
                <th scope="col">Active</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody>
              {DataList?.map((res, index) => (
                <tr>
                  <td scope="col">{index + 1}</td>
                  <td scope="col">{res?.name}</td>
                  <td scope="col"><img
                        src={
                          res?.image
                            ? backendImagePath + res?.image
                            : backendImagePath+imageNotfound
                        }
                        alt={res?.name}
                      /></td>
                  <td scope="col">
                    {res?.active == "1" ? (
                      <span className="active">Active</span>
                    ) : (
                      <span className="in_active">Inactive</span>
                    )}
                  </td>
                  <td scope="col">
                    <button
                      onClick={() => routeChange(MyRouts.teamEdit + res?.id)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default TeamList;
