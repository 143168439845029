import React, { useState } from "react";
import "./style.css";
import { addPageContentCall } from "../../services/ApiCall";
import { useNavigate } from "react-router-dom";
import MyRouts from "../../MyRouts";
import Notify from "../../../common/Notify";
import JoditEditor from "jodit-react";

const PageContentAdd = () => {
  const config = {
    readonly: false,
    height: 500,
  };
  const [Input, setInput] = useState({
    title: "",
    des: "",
    type: "",
    active: 0,
  });

  const [message, setMessage] = useState([]);

  const [status, setStatus] = useState(false);
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();

  const routeChange = (page) => {
    navigate(page);
  };

  const inputChange = (e) => {
    setInput({
      ...Input,
      [e.target.name]: e.target.value,
    });
  };

  const reSetForm = () => {
    setInput({
      ...Input,
      title: "",
      des: "",
      type: "",
      active: 0,
    });
  };

  const savePageContent = () => {
    addPageContentCall(Input)
      .then((res) => {
        setFormError(res?.error ? res?.error : {});
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          reSetForm();
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="row">
        {message?.map((res) => (
          <Notify
            msg={res}
            status={status}
            setMessage={() => {
              setMessage([]);
            }}
          />
        ))}
        <div className="col-md-6 page_heading">
          <h3>Page Content Add</h3>
        </div>
        <div className="col-md-6 create_new_with_heading">
          <button
            onClick={() => {
              routeChange(MyRouts.contentList);
            }}
          >
            Back
          </button>
        </div>
        <hr />

       

        <div className="col-md-6 mtop_20">
          <label>type</label>
          <input
            type="text"
            name="type"
            className="form-control "
            placeholder="type"
            value={Input.type}
            onChange={inputChange}
          />
          <p className="input_error_message">{formError.type}</p>
        </div>
        <div className="col-md-6 mtop_20">
          <label>Status select</label>
          <select
            name="active"
            className="form-control "
            onChange={inputChange}
            value={Input.active}
          >
            <option value={1}>Active</option>
            <option value={0}>Inactive</option>
          </select>
        </div>
        <div className="col-md-12 mtop_20">
          <label>title</label>
          <input
            type="text"
            name="title"
            className="form-control "
            placeholder="title"
            value={Input.title}
            onChange={inputChange}
          />
          <p className="input_error_message">{formError.title}</p>
        </div>
        <div className="col-md-12 mtop_20">
          <JoditEditor
            config={config}
            value={Input.des}
            onBlur={(value) => {
              setInput({
                ...Input,
                des: value,
              });
            }}
          />
        </div>
        
        <div className="col-md-6 mtop_20">
          <button
            className="btn btn-primary"
            onClick={() => {
              savePageContent();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
export default PageContentAdd;