import axios from "axios";
import {
  loginApiUrl,
  signupApiUrl,
  logOutApiUrl,
  userValidApiUrl,
  getSoicalLinkApiUrl,
  getAllMcqTypeApiUrl,
  updateSocialLinkApiUrl,
  addMcqTypeApiUrl,
  getMcqTypeApiUrl,
  addMcqApiUrl,
  updateMcqTypeApiUrl,
  getAllMcqApiUrl,
  getAlljobApiUrl,
  getMcqDetailsApiUrl,
  updateMcqApiUrl,
  addJobApiUrl,
  getJobDetailsApiUrl,
  updateJobApiUrl,
  addImageApiUrl,
  getImageListApiUrl,
  deleteImageApiUrl,
  getAllPageContentApiUrl,
  addPageContentApiUrl,
  getPageContentDetailsApiUrl,
  updatePageContentApiUrl,
  addTeamApiUrl,
  getAllTeamApiUrl,
  getTeamDetailsApiUrl,
  updateTeamApiUrl,
  getAllSiteQueryApiUrl,
} from "./apiUrl";

import { FormDecode } from "./Helper";

const LoginApiCall = (input) => {
  return axios({
    method: "post",
    url: loginApiUrl,
    data: input,
    config: { headers: { "Content-Type": " application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const LogOutApiCall = () => {
  return axios({
    method: "post",
    url: logOutApiUrl,
    data: { username: localStorage.getItem("username") },
    config: { headers: { "Content-Type": " application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const userValidApiCall = () => {
  return axios({
    method: "post",
    url: userValidApiUrl,
    data: {
      username: localStorage.getItem("username"),
      token: localStorage.getItem("token"),
    },
    headers: {
      mytoken: localStorage.getItem("token"),
    },
    config: { headers: { "Content-Type": " application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const getSoicalLinkApiCall = () => {
  return axios({
    method: "post",
    url: getSoicalLinkApiUrl,
    data: {
      username: localStorage.getItem("username"),
      token: localStorage.getItem("token"),
    },
    config: { headers: { "Content-Type": " application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const getAllMcqTypeListApiCall = () => {
  return axios({
    method: "post",
    url: getAllMcqTypeApiUrl,
    data: {
      username: localStorage.getItem("username"),
      token: localStorage.getItem("token"),
    },
    config: { headers: { "Content-Type": " application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const getAllMcqListApiCall = (type) => {
  return axios({
    method: "post",
    url: getAllMcqApiUrl,
    data: {
      mcqType: type.McqType,
      page: type.page,
      pageLength: type.pageLength,
      username: localStorage.getItem("username"),
      token: localStorage.getItem("token"),
    },
    config: { headers: { "Content-Type": " application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const getMcqDetails = (id) => {
  return axios({
    method: "post",
    url: getMcqDetailsApiUrl,
    data: {
      mcqId: id,
      username: localStorage.getItem("username"),
      token: localStorage.getItem("token"),
    },
    config: { headers: { "Content-Type": " application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const getJobDetails = (id) => {
  return axios({
    method: "post",
    url: getJobDetailsApiUrl,
    data: {
      jobId: id,
      username: localStorage.getItem("username"),
      token: localStorage.getItem("token"),
    },
    config: { headers: { "Content-Type": " application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const getAllJobListApiCall = (type) => {
  return axios({
    method: "post",
    url: getAlljobApiUrl,
    data: {
      page: type.page,
      postType: type.PostType,
      pageLength: type.pageLength,
      username: localStorage.getItem("username"),
      token: localStorage.getItem("token"),
    },
    config: { headers: { "Content-Type": " application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const SignupApiCall = (input) => {
  return axios({
    method: "post",
    url: signupApiUrl,
    data: input,
    config: { headers: { "Content-Type": " application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const updateSocialLinkApiCall = (input) => {
  return axios({
    method: "post",
    url: updateSocialLinkApiUrl,
    data: input,
    config: { headers: { "Content-Type": " application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const addMcqApiCall = (input) => {
  return axios({
    method: "post",
    url: addMcqApiUrl,
    data: input,
    config: { headers: { "Content-Type": " application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};
const updateMcqApiCall = (input) => {
  return axios({
    method: "post",
    url: updateMcqApiUrl,
    data: input,
    config: { headers: { "Content-Type": " application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const getActiveMcqType = () => {
  return axios({
    method: "get",
    url: getMcqTypeApiUrl,
    config: { headers: { "Content-Type": " application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const updateMcqTypeApiCall = (input) => {
  let formData = new FormData();
  formData.append("id", input?.id);
  formData.append("name", input?.name);
  formData.append("slug", input?.slug);
  formData.append("image", input?.image);
  formData.append("active", input?.active);
  formData.append("public", input?.public);
  formData.append("username", input?.username);
  formData.append("token", input?.token);
  return axios({
    method: "post",
    url: updateMcqTypeApiUrl,
    data: formData,
    config: { headers: { "Content-Type": "application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const addMcqTypeApiCall = (input) => {
  let formData = new FormData();
  formData.append("name", input?.name);
  formData.append("slug", input?.slug);
  formData.append("image", input?.image);
  formData.append("active", input?.active);
  formData.append("username", input?.username);
  formData.append("token", input?.token);
  return axios({
    method: "post",
    url: addMcqTypeApiUrl,
    data: formData,
    config: { headers: { "Content-Type": "application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const addImageApiCall = (input) => {
  let formData = new FormData();
  formData.append("image", input?.image);
  return axios({
    method: "post",
    url: addImageApiUrl,
    data: formData,
    headers: {
      myusername: localStorage.getItem("username"),
      mytoken: localStorage.getItem("token"),
    },
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const addPageContentCall = (input) => {
  let formData = new FormData();
  formData.append("title", input?.title);
  formData.append("type", input?.type);
  formData.append("des", input?.des);
  formData.append("active", input?.active);
  return axios({
    method: "post",
    url: addPageContentApiUrl,
    data: formData,
    headers: {
      myusername: localStorage.getItem("username"),
      mytoken: localStorage.getItem("token"),
    },
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const addTeamApiCall = (input) => {
  let formData = new FormData();
  formData.append("name", input?.name);
  formData.append("image", input?.image);
  formData.append("des", input?.des);
  formData.append("active", input?.active);
  return axios({
    method: "post",
    url: addTeamApiUrl,
    data: formData,
    headers: {
      myusername: localStorage.getItem("username"),
      mytoken: localStorage.getItem("token"),
    },
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const updatePageContentApiCall = (input) => {
  let formData = new FormData();
  formData.append("title", input?.title);
  formData.append("type", input?.type);
  formData.append("des", input?.des);
  formData.append("active", input?.active);
  formData.append("id", input?.id);
  return axios({
    method: "post",
    url: updatePageContentApiUrl,
    data: formData,
    headers: {
      myusername: localStorage.getItem("username"),
      mytoken: localStorage.getItem("token"),
    },
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const updateTeamApiCall = (input) => {
  let formData = new FormData();
  formData.append("name", input?.name);
  formData.append("image", input?.image);
  formData.append("des", input?.des);
  formData.append("active", input?.active);
  formData.append("id", input?.id);
  return axios({
    method: "post",
    url: updateTeamApiUrl,
    data: formData,
    headers: {
      myusername: localStorage.getItem("username"),
      mytoken: localStorage.getItem("token"),
    },
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const deleteImageApiCall = (id) => {
  let formData = new FormData();
  formData.append("id", id);
  return axios({
    method: "post",
    url: deleteImageApiUrl,
    data: formData,
    headers: {
      myusername: localStorage.getItem("username"),
      mytoken: localStorage.getItem("token"),
    },
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const getImageListApiCall = () => {
  return axios({
    method: "get",
    url: getImageListApiUrl,
    headers: {
      myusername: localStorage.getItem("username"),
      mytoken: localStorage.getItem("token"),
    },
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const getAllTeamApiCall = () => {
  return axios({
    method: "get",
    url: getAllTeamApiUrl,
    headers: {
      myusername: localStorage.getItem("username"),
      mytoken: localStorage.getItem("token"),
    },
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const getAllSiteQueryApiCall = () => {
  return axios({
    method: "get",
    url: getAllSiteQueryApiUrl,
    headers: {
      myusername: localStorage.getItem("username"),
      mytoken: localStorage.getItem("token"),
    },
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const getAllPageContentApiCall = () => {
  return axios({
    method: "get",
    url: getAllPageContentApiUrl,
    headers: {
      myusername: localStorage.getItem("username"),
      mytoken: localStorage.getItem("token"),
    },
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const getPageContentDetailsApiCall = (id) => {
  return axios({
    method: "get",
    url: `${getPageContentDetailsApiUrl}?id=${id}`,
    headers: {
      myusername: localStorage.getItem("username"),
      mytoken: localStorage.getItem("token"),
    },
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const getTeamDetailsApiCall = (id) => {
  return axios({
    method: "get",
    url: `${getTeamDetailsApiUrl}?id=${id}`,
    headers: {
      myusername: localStorage.getItem("username"),
      mytoken: localStorage.getItem("token"),
    },
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const addJobApiCall = (input) => {
  let formData = new FormData();
  formData.append("title", input?.title);
  formData.append("slug", input?.slug);
  formData.append("image", input?.image);
  formData.append("active", input?.active);
  formData.append("des", input?.des);
  formData.append("username", input?.username);
  formData.append("token", input?.token);
  formData.append("type", input?.type);
  formData.append("pagetitle", input?.pagetitle);
  formData.append("metades", input?.metades);
  formData.append("metakey", input?.metakey);
  return axios({
    method: "post",
    url: addJobApiUrl,
    data: formData,
    config: { headers: { "Content-Type": "application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

const updateJobApiCall = (input) => {
  let formData = new FormData();
  formData.append("title", input?.title);
  formData.append("id", input?.id);
  formData.append("slug", input?.slug);
  formData.append("image", input?.image);
  formData.append("active", input?.active);
  formData.append("des", input?.des);
  formData.append("username", input?.username);
  formData.append("token", input?.token);
  formData.append("type", input?.type);
  formData.append("pagetitle", input?.pagetitle);
  formData.append("metades", input?.metades);
  formData.append("metakey", input?.metakey);
  return axios({
    method: "post",
    url: updateJobApiUrl,
    data: formData,
    config: { headers: { "Content-Type": "application/json" } },
  })
    .then((responce) => responce.data)
    .catch((error) => error);
};

export {
  updateTeamApiCall,
  getTeamDetailsApiCall,
  getAllTeamApiCall,
  addTeamApiCall,
  updatePageContentApiCall,
  getPageContentDetailsApiCall,
  addPageContentCall,
  getAllPageContentApiCall,
  deleteImageApiCall,
  getImageListApiCall,
  addImageApiCall,
  updateJobApiCall,
  getJobDetails,
  addJobApiCall,
  updateMcqApiCall,
  getMcqDetails,
  getAllMcqListApiCall,
  getAllJobListApiCall,
  getAllMcqTypeListApiCall,
  addMcqTypeApiCall,
  getActiveMcqType,
  addMcqApiCall,
  LoginApiCall,
  SignupApiCall,
  updateSocialLinkApiCall,
  LogOutApiCall,
  userValidApiCall,
  getSoicalLinkApiCall,
  updateMcqTypeApiCall,
  getAllSiteQueryApiCall,
};
