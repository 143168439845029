import React, { useState } from "react";
import "./style.css";
import { addJobApiCall } from "../../services/ApiCall";
import { useNavigate } from "react-router-dom";
import MyRouts from "../../MyRouts";
import ImageList from "../../ImageList";
import Notify from "../../../common/Notify";
import JoditEditor from "jodit-react";
import { typeList } from "../../../common/ImporentDate";

const JobAdd = () => {
  const config = {
    readonly: false,
    height: 500,
  };

  const [Input, setInput] = useState({
    title: "",
    slug: "",
    image: null,
    des: "",
    active: 0,
    type: "",
    pagetitle: "",
    metades: "",
    metakey: "",
    username: localStorage.getItem("username"),
    token: localStorage.getItem("token"),
  });

  const [message, setMessage] = useState([]);
  const [status, setStatus] = useState(false);
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();

  const routeChange = (page) => {
    navigate(page);
  };

  const inputChange = (e) => {
      setInput({
      ...Input,
      [e.target.name]: e.target.value,
    });
  };

  const reSetForm = () => {
    setInput({
      ...Input,
      title: "",
      slug: "",
      active: 0,
      type: "",
      image: null,
      pagetitle: "",
      metades: "",
      metakey: "",
      des: "",
    });
  };

  const saveNewJob = () => {
    addJobApiCall(Input)
      .then((res) => {
        setFormError(res?.error ? res?.error : {});
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          reSetForm();
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="row">
        {message?.map((res) => (
          <Notify
            msg={res}
            status={status}
            setMessage={() => {
              setMessage([]);
            }}
          />
        ))}
        <ImageList />
        <div className="col-md-6 page_heading">
          <h3>New Post Add</h3>
        </div>
        <div className="col-md-6 create_new_with_heading">
          <button
            onClick={() => {
              routeChange(MyRouts.jobList);
            }}
          >
            Back
          </button>
        </div>
        <hr />

        <div className="col-md-6 mtop_20">
          <label>Type</label>
          <select
            className="form-control "
            name="type"
            value={Input.type}
            onChange={inputChange}
          >
            {typeList.map((res) => (
              <option value={res.value}>{res.name}</option>
            ))}
          </select>
          <p className="input_error_message">{formError.type}</p>
        </div>
        <div className="col-md-6 mtop_20">
          <label>Page title</label>
          <input
            type="text"
            name="pagetitle"
            className="form-control "
            placeholder="page title"
            value={Input.pagetitle}
            onChange={inputChange}
          />
          <p className="input_error_message">{formError.pagetitle}</p>
        </div>
        <div className="col-md-6 mtop_20">
          <label>Meta des</label>
          <input
            type="text"
            name="metades"
            className="form-control "
            placeholder="meta des"
            value={Input.metades}
            onChange={inputChange}
          />
          <p className="input_error_message">{formError.metades}</p>
        </div>
        <div className="col-md-6 mtop_20">
          <label>meta key</label>
          <input
            type="text"
            name="metakey"
            className="form-control "
            placeholder="meta key"
            value={Input.metakey}
            onChange={inputChange}
          />
          <p className="input_error_message">{formError.metakey}</p>
        </div>
        <div className="col-md-6 mtop_20">
          <label>title</label>
          <input
            type="text"
            name="title"
            className="form-control "
            placeholder="title"
            value={Input.title}
            onChange={inputChange}
          />
          <p className="input_error_message">{formError.title}</p>
        </div>
        <div className="col-md-6 mtop_20">
          <label>slug</label>
          <input
            type="text"
            className="form-control "
            name="slug"
            value={Input.slug}
            placeholder="Slug"
            onChange={inputChange}
            onBlur={()=>{
              setInput({
                ...Input,
                slug: Input.slug.toLowerCase().trim().replace(/[^\w\s-]/g,'').replace(/[\s_-]+/g,'-').replace(/^-+|-+$/g,''),
              });
            }}
          />
          <p className="input_error_message">{formError.slug}</p>
        </div>
        <div className="col-md-6 mtop_20">
          <label>Status select</label>
          <select
            name="active"
            className="form-control "
            onChange={inputChange}
            value={Input.active}
          >
            <option value={1}>Active</option>
            <option value={0}>Inactive</option>
          </select>
        </div>
        <div className="col-md-6 mtop_20">
          {Input?.imageShow && (
            <img
              style={{ width: "50%" }}
              src={Input?.imageShow}
              alt={Input?.title}
            />
          )}
          <label>Image</label>
          <input
            className="form-control "
            type="file"
            onChange={(e) => {
              if (e.target.files.length != 0) {
                setInput({
                  ...Input,
                  image: e.target.files[0],
                  imageShow: URL.createObjectURL(e.target.files[0]),
                });
              }
            }}
          />
        </div>
        <div className="col-md-12 mtop_20">
          <JoditEditor
            config={config}
            value={Input.des}
            onBlur={(value) => {
              setInput({
                ...Input,
                des: value,
              });
            }}
          />
        </div>
        <div className="col-md-6 mtop_20">
          <button
            className="btn btn-primary"
            onClick={() => {
              saveNewJob();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
export default JobAdd;
