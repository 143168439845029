import React, { useEffect, useState } from "react";
import "./style.css";
import { addMcqApiCall ,getActiveMcqType} from "../../services/ApiCall";
import { useNavigate } from "react-router-dom";
import MyRouts from "../../MyRouts";
import Notify from "../../../common/Notify";

const McqAdd = () => {
  const [opection, setOpection] = useState([
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
  ]);
  const [quection, setQuectin] = useState("");
  const [McqType, setMcqType] = useState("");
  const [rightAns, setRightAns] = useState(null);
  const [Active, setActive] = useState(0);
  const [McqTypeList, setMcqTypeList] = useState([]);
  const [message, setMessage] = useState([]);

  const [status, setStatus] = useState(false);
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();

  useEffect(()=>{
    getMcqType()
  },[])

  const routeChange = (page) => {
    navigate(page);
  };

  const onOpectionChange = (e, index) => {
    let newopection = [...opection];
    newopection[index].value = e.target.value;
    setOpection(newopection);
  };

  const reSetForm = () => {
    setOpection([
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
    ]);
    setQuectin("")
    setMcqType("")
    setRightAns(null)
    setActive(0)
  };

  const getMcqType = () => {
    getActiveMcqType()
      .then((res) => {
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          setMcqTypeList(res?.mcq_type);
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const saveMcq = () => {
    addMcqApiCall({
      que: quection,
      op1: opection[0]?.value,
      op2: opection[1]?.value,
      op3: opection[2]?.value,
      op4: opection[3]?.value,
      op5: opection[4]?.value,
      op6: opection[5]?.value,
      ans: opection[rightAns]?.value,
      type: McqType,
      active: Active,
      username: localStorage.getItem("username"),
      token: localStorage.getItem("token"),
    })
      .then((res) => {
        setFormError(res?.error ? res?.error : {});
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          reSetForm();
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="row">
        {message?.map((res) => (
          <Notify
            msg={res}
            status={status}
            setMessage={() => {
              setMessage([]);
            }}
          />
        ))}
        <div className="col-md-6 page_heading">
          <h3>Mcq Add</h3>
        </div>
        <div className="col-md-6 create_new_with_heading">
          <button
            onClick={() => {
              routeChange(MyRouts.mcqList);
            }}
          >
            Back
          </button>
        </div>
        <hr />
        <div className="col-md-12">
          <div className="input_div">
            <select
              className="form-control"
              onChange={(e) => {
                setMcqType(e.target.value);
              }}
              value={McqType}
            >
              <option value="">Select Type</option>
              {McqTypeList.map((res)=>
              <option value={res?.slug} key={res?.slug}>{res?.name}</option>
              )}
            </select>
            <p className="input_error_message">{formError?.type}</p>
          </div>
          <div className="input_div">
            <span>Quection</span>{" "}
            <input
              type="checkbox"
              checked={Active}
              onChange={(e) => {
                setActive(e.target.checked ? 1 : 0);
              }}
            />{" "}
            <span>Active</span>
            <textarea
              type="text"
              className="form-control"
              placeholder="Quection"
              onChange={(e) => {
                setQuectin(e.target.value);
              }}
              value={quection}
            />
               <p className="input_error_message">{formError?.que}</p>
               <p className="input_error_message">{formError?.ans}</p>
          </div>
          {opection.map(({ value }, index) => (
            <div className="input_div" key={`mcq_opectop${index}`}>
              <span>Opection {index + 1}</span>
              <input
                type="checkbox"
                checked={rightAns == index}
                onChange={() => {
                  setRightAns(index);
                }}
              />{" "}
              <span>Right ans</span>
              <textarea
                type="text"
                value={value}
                className="form-control"
                placeholder={`Opection ${index + 1}`}
                onChange={(e) => {
                  onOpectionChange(e, index);
                }}
              />
            </div>
          ))}
          <div className="input_div">
            <button onClick={saveMcq}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default McqAdd;
