import React, { useEffect, useState } from "react";
import "./style.css";

import { useNavigate, useParams } from "react-router-dom";
import MyRouts from "../../MyRouts";
import Notify from "../../../common/Notify";
import {
  getActiveMcqType,
  getMcqDetails,
  updateMcqApiCall,
} from "../../services/ApiCall";

const opection = ["op1", "op2", "op3", "op4", "op5", "op6"];

const McqEdit = () => {
  const navigate = useNavigate();
  const { mcqId } = useParams();
  const [McqTypeList, setMcqTypeList] = useState([]);
  const [message, setMessage] = useState([]);
  const [status, setStatus] = useState(false);
  const [inputValue, setInputValue] = useState({});
  const [formError, setFormError] = useState({});

  useEffect(() => {
    getMcqType();
  }, []);

  useEffect(() => {
    mcqId && getDetails();
  }, [mcqId]);

  const getDetails = () => {
    getMcqDetails(mcqId)
      .then((res) => {
        setFormError(res?.error);
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          setInputValue({ ...res?.data[0] });
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMcqType = () => {
    getActiveMcqType()
      .then((res) => {
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          setMcqTypeList(res?.mcq_type);
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const routeChange = (page) => {
    navigate(page);
  };

  const inputChange = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value,
    });
  };

  const updateMcq = () => {
    updateMcqApiCall({
      ...inputValue,
      username: localStorage.getItem("username"),
      token: localStorage.getItem("token"),
    })
      .then((res) => {
        setFormError(res?.error ? res?.error : {});
        setStatus(res?.status ? res?.status : false);
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log(inputValue);
  return (
    <div>
      <div className="row">
        {message?.map((res) => (
          <Notify
            msg={res}
            status={status}
            setMessage={() => {
              setMessage([]);
            }}
          />
        ))}
        <div className="col-md-6 page_heading">
          <h3>Mcq Edit {mcqId}</h3>
        </div>
        <div className="col-md-6 create_new_with_heading">
          <button
            onClick={() => {
              routeChange(MyRouts.mcqList);
            }}
          >
            Back
          </button>
        </div>
        <hr />
        <div className="col-md-12">
          <div className="input_div">
            <select
              className="form-control"
              onChange={inputChange}
              name="type"
              value={inputValue.type}
            >
              <option value="">Select Type</option>
              {McqTypeList.map((res) => (
                <option value={res?.slug} key={res?.slug}>
                  {res?.name}
                </option>
              ))}
            </select>
            <p className="input_error_message">{formError?.type}</p>
          </div>
          <div className="input_div">
            <span>Quection</span>{" "}
            <input
              name="active"
              type="checkbox"
              checked={inputValue.active}
              onChange={(e) => {
                setInputValue({
                  ...inputValue,
                  active: e.target.checked ? 1 : 0,
                });
              }}
            />
            <span>Active</span>
            <textarea
              type="text"
              name="que"
              className="form-control"
              placeholder="Quection"
              onChange={inputChange}
              value={inputValue.que}
            />
            <p className="input_error_message">{formError?.que}</p>
            <p className="input_error_message">{formError?.ans}</p>
          </div>
          {opection.map((res, index) => (
            <div className="input_div" key={`mcq_opectop${index}`}>
              <span>Opection {index + 1}</span>
              <input
                name="ans"
                type="checkbox"
                checked={inputValue[res] == inputValue.ans}
                onChange={() => {
                  setInputValue({
                    ...inputValue,
                    ans: inputValue[res],
                  });
                }}
              />{" "}
              <span>Right ans</span>
              <textarea
                name={res}
                type="text"
                value={inputValue[res]}
                className="form-control"
                placeholder={`Opection ${index + 1}`}
                onChange={inputChange}
              />
            </div>
          ))}
          <div className="input_div">
            <button onClick={updateMcq}>Update</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default McqEdit;
