import { toPng } from "html-to-image";
import { useState } from "react";

const ImageCreate = () => {
  const [imageData, setImageData] = useState({
    widthCreate: 320,
    heightCreate: 180,
    width: 320,
    height: 180,
    borderRadius: 0,
    name: "newvacancy_org.png",
  });

  const DownloadImage = () => {
    toPng(document.getElementById("image-download")).then(function (dataUrl) {
      saveAs(dataUrl, imageData?.name ? imageData?.name : "newvacancy_org.png");
    });
  };

  const saveAs = (blob, fileName) => {
    var elem = window.document.createElement("a");
    elem.href = blob;
    elem.download = fileName;
    elem.style = "display:none;";
    (document.body || document.documentElement).appendChild(elem);
    if (typeof elem.click === "function") {
      elem.click();
    } else {
      elem.target = "_blank";
      elem.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      );
    }
    URL.revokeObjectURL(elem.href);
    elem.remove();
  };

  return (
    <div className="image-create">
      <h1>Create New Image</h1>
      <div className="row">
        <div className="col-md-6 mtop_20">
          <label>Image</label>
          <input
            type="file"
            className="form-control"
            onChange={(e) => {
              if (e.target.files.length != 0) {
                setImageData({
                  ...imageData,
                  image: URL.createObjectURL(e.target.files[0]),
                });
              }
            }}
          />
        </div>
        <div className="col-md-6 mtop_20">
          <label>Footer</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Footer"
            value={imageData?.footer}
            onChange={(e) => {
              setImageData({
                ...imageData,
                footer: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-md-6 mtop_20">
          <label>Image Text</label>
          <input
            type="text"
            value={imageData?.imageText}
            onChange={(e) => {
              setImageData({
                ...imageData,
                imageText: e.target.value,
              });
            }}
            className="form-control"
            placeholder="Enter Image Text"
          />
        </div>
        <div className="col-md-6 mtop_20">
          <label>Border radius</label>
          <input
            type="range"
            max="50"
            min="0"
            style={{ width: "100%" }}
            value={imageData?.borderRadius}
            onChange={(e) => {
              setImageData({
                ...imageData,
                borderRadius: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-md-3 mtop_20">
          <label>Image Width</label>
          <input
            type="number"
            className="form-control"
            value={imageData?.width}
            onChange={(e) => {
              setImageData({
                ...imageData,
                width: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-md-3 mtop_20">
          <label>Image Height</label>
          <input
            type="number"
            className="form-control"
            value={imageData?.height}
            onChange={(e) => {
              setImageData({
                ...imageData,
                height: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-md-6 mtop_20">
          <label>Image name</label>
          <input
            type="text"
            className="form-control"
            value={imageData?.name}
            onChange={(e) => {
              setImageData({
                ...imageData,
                name: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-md-3 mtop_20">
          <label>Create Image Width</label>
          <input
            type="number"
            className="form-control"
            value={imageData?.widthCreate}
            onChange={(e) => {
              setImageData({
                ...imageData,
                widthCreate: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-md-3 mtop_20">
          <label>Create Image Height</label>
          <input
            type="number"
            className="form-control"
            value={imageData?.heightCreate}
            onChange={(e) => {
              setImageData({
                ...imageData,
                heightCreate: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-md-6 mtop_20">
          <button className="btn btn-primary" onClick={DownloadImage}>
            Download
          </button>
        </div>
      </div>
      <div
        id="image-download"
        style={{
          width: `${imageData?.widthCreate}px`,
          height: `${imageData?.heightCreate}px`,
          borderRadius: `${imageData?.borderRadius}%`,
        }}
      >
        {imageData?.image && (
          <img
            src={imageData?.image}
            style={{
              width: `${imageData?.width}px`,
              height: `${imageData?.height}px`,
            }}
          />
        )}
        {imageData?.imageText && (
          <p className="img-text">{imageData?.imageText}</p>
        )}
        {imageData?.footer && <p className="img-footer">{imageData?.footer}</p>}
      </div>
    </div>
  );
};
export default ImageCreate;
