import React, { useState, useEffect } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import MyRouts from "../../MyRouts";

import { getAllMcqListApiCall, getActiveMcqType } from "../../services/ApiCall";
import Notify from "../../../common/Notify";

const pageLengthArray = [10,20,30,50,70,100,500];

const McqList = () => {
  const [message, setMessage] = useState([]);
  const [McqTypeList, setMcqTypeList] = useState([]);
  const [McqType, setMcqType] = useState("");
  const [mcqList, setMcqList] = useState([]);
  const [status, setStatus] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLength, setPageLength] = useState(10);
  const [pageArray, setPageArray] = useState([]);
console.log(pageArray)
  useEffect(() => {
    getMcqType();
  }, []);

  useEffect(() => {
    McqType && getAllMcq();
  }, [McqType,page,pageLength]);

  const getMcqType = () => {
    getActiveMcqType()
      .then((res) => {
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          setMcqTypeList(res?.mcq_type);
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllMcq = () => {
    getAllMcqListApiCall({McqType,page,pageLength})
      .then((res) => {
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          setMcqList(res?.data);
          setPageArray([...new Array(Math.ceil((+res?.page) / pageLength))].map(()=>0));
        } else {
          setMcqList([]);
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const navigate = useNavigate();
  const routeChange = (page) => {
    navigate(page);
  };

  return (
    <div>
      {message?.map((res) => (
        <Notify
          msg={res}
          status={status}
          setMessage={() => {
            setMessage([]);
          }}
        />
      ))}
      <div className="row">
        <div className="col-md-4 page_heading">
          <h3>Mcq List</h3>
        </div>
        <div className="col-md-4">
          <select
            className="form-control"
            onChange={(e) => {
              setPage(1)
              setMcqType(e.target.value);
            }}
            value={McqType}
          >
            <option value="">Select Type</option>
            {McqTypeList.map((res) => (
              <option value={res?.slug} key={res?.slug}>
                {res?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-2 create_new_with_heading">
        <select
            className="form-control"
            onChange={(e) => {
              setPage(1)
              setPageLength(e.target.value);
            }}
            value={pageLength}
          >
            <option value="">Select Page Length</option>
            {pageLengthArray.map((res) => (
              <option value={res} key={res}>
                {res}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-2 create_new_with_heading">
          <button
            onClick={() => {
              routeChange(MyRouts.mcqAdd);
            }}
          >
            Add New Mcq
          </button>
        </div>
      </div>

      {McqType && (
        <div className="row">
          <div className="col-md-12">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Quection</th>
                  <th scope="col">Active</th>
                  <th scope="col">Edit</th>
                </tr>
              </thead>
              <tbody>
                {mcqList?.map((res, index) => (
                  <tr>
                    <td scope="col">{index + 1}</td>
                    <td scope="col">{res?.que}</td>
                    <td scope="col">
                      {res?.active == "1" ? (
                        <span className="active">Active</span>
                      ) : (
                        <span className="in_active">Inactive</span>
                      )}
                    </td>
                    <td scope="col">
                      <button onClick={() => routeChange(MyRouts.mcqEdit+res?.id)}>Edit</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-md-12">
            {pageArray.map((res, index) => (
              <button
                onClick={() => {
                  setPage(index + 1);
                }}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default McqList;
