import React, { useState, useEffect } from "react";

import { getAllSiteQueryApiCall } from "./services/ApiCall";
import Notify from "../common/Notify";

const SiteQuery = () => {
  const [message, setMessage] = useState([]);
  const [DataList, setDataList] = useState([]);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    getAllJobs();
  }, []);

  const getAllJobs = () => {
    getAllSiteQueryApiCall()
      .then((res) => {
        setStatus(res?.status ? res?.status : false);
        if (res?.status == true) {
          setDataList(res?.data);
        } else {
          setDataList([]);
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {message?.map((res) => (
        <Notify
          msg={res}
          status={status}
          setMessage={() => {
            setMessage([]);
          }}
        />
      ))}
      <div className="row">
        <div className="col-md-12 page_heading">
          <h3>Site Query List</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">S.No.</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">phone</th>
                <th scope="col">message</th>
              </tr>
            </thead>
            <tbody>
              {DataList?.map((res, index) => (
                <tr>
                  <td scope="col">{index + 1}</td>
                  <td scope="col">{res?.name}</td>
                  <td scope="col">{res?.email}</td>
                  <td scope="col">{res?.phone}</td>
                  <td scope="col">{res?.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default SiteQuery;
