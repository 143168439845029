import React, { useState, useEffect } from "react";
import Notify from "../../../common/Notify";
import "./style.css";
import {
  getSoicalLinkApiCall,
  updateSocialLinkApiCall,
} from "../../services/ApiCall";

const InputBox = [
  "facebook",
  "whatsapp",
  "whatsapp_group_1",
  "whatsapp_group_2",
  "whatsapp_group_3",
  "telegram",
  "youtube",
  "instagram",
  "twitter",
  "linkedin",
];
 
const SocialLink = () => {
  const [message, setMessage] = useState([]);
  const [status, setStatus] = useState(false);
  const [Input, setInput] = useState({
    username: localStorage.getItem("username"),
    token: localStorage.getItem("token"),
  });

  useEffect(() => {
    getSoicalLink();
  }, []);

  const inputChange = (e) => {
    setInput({
      ...Input,
      [e.target.name]: e.target.value,
    });
  };

  const getSoicalLink = () => {
    getSoicalLinkApiCall()
      .then((res) => {
        setStatus(res?.status ? res?.status : false);
        if(res?.status){
           setInput({
          ...Input,
          ...res.data[0]
        })
        }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateSocial = () => {
    updateSocialLinkApiCall(Input)
      .then((res) => {
        setStatus(res?.status ? res?.status : false);
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {message?.map((res) => (
        <Notify
          msg={res}
          status={status}
          setMessage={() => {
            setMessage([]);
          }}
        />
      ))}
      <div className="row">
        <div className="col-md-12 page_heading">
          <h3>Social Media Link</h3>
        </div>
        {InputBox?.map((res) => (
          <div className="col-md-6 mtop_20">
            <label>{res}</label>
            <input
              name={res}
              className="form-control"
              value={Input[res]}
              onChange={inputChange}
            />
          </div>
        ))}
        <div className="col-md-12 mtop_20">
          <button
            className="btn btn-primary"
            onClick={() => {
              updateSocial();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
export default SocialLink;
