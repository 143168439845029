import React from "react";
// import { Outlet, useNavigate } from "react-router-dom";
import Sidebar_header from "./Layout_pages/Sidebar_header";

const Layout = () => {
  return (
    <div className="main_content_website">
      <Sidebar_header />
    </div>
  );
};
export default Layout;
