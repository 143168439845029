import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./component/Latyout";
import MyRouts from "./component/MyRouts";
import LoginPage from "./component/authPage/LoginPage";
import SignupPage from "./component/authPage/SignupPage";
import SocialLink from "./component/pages/Social/SocialLink";
import Dashbord from "./component/pages/dashbord/Dashbord";
import McqAdd from "./component/pages/Mcq/McqAdd";
import McqList from "./component/pages/Mcq/McqList";
import McqTypeList from "./component/pages/Mcq/McqTypeList";
import JobList from "./component/pages/Job/JobList";
import JobAdd from "./component/pages/Job/JobAdd";
import McqEdit from "./component/pages/Mcq/McqEdit";
import JobEdit from "./component/pages/Job/JobEdit";
import PageContentList from "./component/pages/page-content/PageContentList";
import PageContentAdd from "./component/pages/page-content/PageContentAdd";
import PageContentEdit from "./component/pages/page-content/PageContentEdit";
import TeamList from "./component/pages/team/TeamList";
import TeamAdd from "./component/pages/team/TeamAdd";
import TeamEdit from "./component/pages/team/TeamEdit";
import ImageCreate from "./component/ImageCreate";
import SiteQuery from "./component/SiteQuery";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={MyRouts.Login} element={<LoginPage />} />
        <Route path={MyRouts.SignUp} element={<SignupPage />} />

        <Route path="/" element={<Layout />}>
          <Route path={MyRouts.SocialUrl} element={<SocialLink />} />
          <Route path={MyRouts.mcqTypeUrl} element={<McqTypeList />} />

          <Route path={MyRouts.mcqAdd} element={<McqAdd />} />
          <Route path={MyRouts.mcqEditUrl} element={<McqEdit />} />
          <Route path={MyRouts.mcqList} element={<McqList />} />

          <Route path={MyRouts.contentList} element={<PageContentList />} />
          <Route path={MyRouts.contentAdd} element={<PageContentAdd />} />
          <Route path={MyRouts.contentEditUrl} element={<PageContentEdit />} />

          <Route path={MyRouts.jobList} element={<JobList />} />
          <Route path={MyRouts.jobAdd} element={<JobAdd />} />
          <Route path={MyRouts.jobEditUrl} element={<JobEdit />} />

          <Route path={MyRouts.teamList} element={<TeamList />} />
          <Route path={MyRouts.teamAdd} element={<TeamAdd />} />
          <Route path={MyRouts.teamEditUrl} element={<TeamEdit />} />

          <Route path={MyRouts.dashbord} element={<Dashbord />} />
          <Route path={MyRouts.imageCreate} element={<ImageCreate />} />
          <Route path={MyRouts.siteQuery} element={<SiteQuery />} />

        </Route>
        <Route path={MyRouts.page404} element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
