const MyRouts = {
    Login:"/",
    SignUp:"/signup",
    SocialUrl:"/social",

    contentList:"/content-list",
    contentAdd:"/content-add",
    contentEdit:"/content-edit/",
    contentEditUrl:"/content-edit/:id",
    
    dashbord:"/dashbord",

    mcqAdd:"/mcq-add",
    mcqEdit:"/mcq-edit/",
    mcqEditUrl:"/mcq-edit/:mcqId",
    mcqList:"/mcq-list",

    mcqTypeUrl:"/mcq-type",

    jobList:"/job-list",
    jobAdd:"/job-add",
    jobEdit:"/job-edit/",
    jobEditUrl:"/job-edit/:jobId",

    teamList:"/team-list",
    teamAdd:"/team-add",
    teamEdit:"/team-edit/",
    teamEditUrl:"/team-edit/:id",
    
    imageCreate:"image-create",
    siteQuery:"site-query",
    
    page404:"*"
}

export default MyRouts;