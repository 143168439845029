import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Notify from "../../common/Notify";
import { LoginApiCall } from "../services/ApiCall";
import "./style.css";
import MyRouts from "../MyRouts";
import { useEffect } from "react";

const LoginPage = () => {
  const [Input, setInput] = useState({
    username: "",
    password: "",
  });

  const [formError, setFormError] = useState({});
  const [message, setMessage] = useState([]);
  const [status, setStatus] = useState(false);

  const navigate = useNavigate();

  const routeChange = (page)=>{
    navigate(page)
  }

  useEffect(()=>{
    localStorage.getItem("token") && routeChange(MyRouts.dashbord)
  },[])

  const reSetForm = () => {
    setInput({
      username: "",
      password: "",
    });
  };
  
  const inputChange = (e) => {
    setInput({
      ...Input,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = () => {
    LoginApiCall(Input)
      .then((res) => {
        setFormError(res?.error ? res?.error : {});
        setStatus(res?.status ? res?.status : false);
       if(res?.status == true){
        localStorage.setItem("username",res?.user_data.username);
        localStorage.setItem("full_name",res?.user_data.full_name);
        localStorage.setItem("token",res?.token);
        reSetForm()
          routeChange(MyRouts.dashbord)
       }
        setMessage(res?.api_message ? res?.api_message : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="container-fluid">
      {message?.map((res) => (
        <Notify msg={res} status={status} setMessage={()=>{setMessage([])}}/>
      ))}
      <div className="row main-content bg-success text-center">
        <div className="col-md-4 text-center company__info">
          <span className="company__logo">
            <h2>
              <span className="fa fa-android"></span>
            </h2>
          </span>
          <h4 className="company_title">Your Company Logo</h4>
        </div>
        <div className="col-md-8 col-xs-12 col-sm-12 login_form ">
          <div className="container-fluid">
            <div className="row login_heading">
              <h2>Log In</h2>
            </div>
            <div className="row">
              <div className="row">
                <input
                  type="text"
                  name="username"
                  className="form__input"
                  placeholder="Username"
                  value={Input.username}
                  onChange={inputChange}
                />
                <p className="input_error_message">{formError?.username}</p>
              </div>
              <div className="row">
                <input
                  type="password"
                  name="password"
                  className="form__input"
                  placeholder="Password"
                  value={Input.password}
                  onChange={inputChange}
                />
                <p className="input_error_message">{formError?.password}</p>
              </div>
              <div className="row">
                <button
                  className="btn"
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  Login
                </button>
              </div>
            </div>
            <div className="row">
              <p>
                Don't have an account? <a href="javascript:void(0);" onClick={()=>{
                  routeChange(MyRouts.SignUp)
                }}>Register Here</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
